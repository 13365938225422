import { ColorOptions } from 'types/color.type';
import {
    ShadowStyleOptions,
    TextAlignHorizontalOptions,
    TextAlignVerticalOptions,
    TextBorderStyleOptions,
    TextDecorationOptions,
    TextDecorationStyleOptions,
    TextStyling,
    TextTransformOptions
} from '../types/layerProperties.type';

export const defaultTextStylingColor = { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1.0 } };

const defaultTextStyling: TextStyling = {
    color: {
        type: ColorOptions.Solid,
        rotation: 90,
        points: [
            { location: 0, color: defaultTextStylingColor },
            { location: 1, color: defaultTextStylingColor }
        ]
    },
    fontSize: { value: 16, unit: 'px' },
    lineHeight: 1.25,
    letterSpacing: 0,
    fontFamily: 'Arial',
    fontSource: 'standard',
    fontVariant: 'regular',
    textTransform: TextTransformOptions.None,
    textAlignHorizontal: TextAlignHorizontalOptions.Left,
    textAlignVertical: TextAlignVerticalOptions.FlexStart,
    textDecoration: TextDecorationOptions.None,
    textDecorationStyle: TextDecorationStyleOptions.Solid,
    textShadow: {
        shadowStyle: ShadowStyleOptions.None,
        color: defaultTextStylingColor,
        xOffset: 4,
        yOffset: 4,
        blur: 4
    },
    textBorder: {
        borderStyle: TextBorderStyleOptions.None,
        borderWidth: { value: 1, unit: 'px' },
        color: defaultTextStylingColor
    }
};

export default defaultTextStyling;
