import { ColorOptions } from 'types/color.type';
import { LayoutGridOptions, ColumnsTypeOptions, LayoutGridUnitOptions } from '../types/formatProperties.type';

export const MAX_AMOUNT_OF_LINES = 50;

const defaultLayoutGrid = {
    activeLayout: LayoutGridOptions.Guidelines,
    color: {
        type: ColorOptions.Solid,
        rotation: 90,
        points: [
            {
                location: 0,
                color: {
                    hex: '#ff0000b3',
                    rgb: {
                        r: 255,
                        g: 0,
                        b: 0,
                        a: 0.7
                    }
                }
            },
            {
                location: 1,
                color: {
                    hex: '#000000',
                    rgb: {
                        r: 0,
                        g: 0,
                        b: 0,
                        a: 1
                    }
                }
            }
        ]
    },
    guidelines: [],
    squareGrid: {
        gridSize: {
            value: 25,
            unit: LayoutGridUnitOptions.Px
        }
    },
    columns: {
        count: 3,
        type: ColumnsTypeOptions.Stretch,
        gutter: {
            value: 10,
            unit: LayoutGridUnitOptions.Px
        },
        margin: {
            value: 10,
            unit: LayoutGridUnitOptions.Px
        },
        width: {
            value: 20,
            unit: LayoutGridUnitOptions.Px
        },
        offset: {
            value: 0,
            unit: LayoutGridUnitOptions.Px
        }
    },
    rows: {
        count: 3,
        type: ColumnsTypeOptions.Stretch,
        gutter: {
            value: 10,
            unit: LayoutGridUnitOptions.Px
        },
        margin: {
            value: 10,
            unit: LayoutGridUnitOptions.Px
        },
        height: {
            value: 20,
            unit: LayoutGridUnitOptions.Px
        },
        offset: {
            value: 0,
            unit: LayoutGridUnitOptions.Px
        }
    }
};

export default defaultLayoutGrid;
