import shortid from 'shortid';
import moment from 'moment';
import Translation from 'components/data/Translation';
import Template, { CurrentView, ExportForPlatform, RightSidebarTab, UnitOptions } from '../types/template.type';
import { defaultFormatProperties } from './layer-properties/default-format-properties';
import { MIGRATION_SCRIPTS_ORDER } from '../migrations/migrateTemplate';
import { DEFAULT_FORMAT_ORDER } from '../constants';
import { defaultTransparent } from './layer-properties/default-color';

const emptyTemplate: Template = {
    id: shortid(),
    designerSettings: {
        disableBase: true,
        customFormats: [],
        autoSave: false,
        enableAnimations: true,
        positionByLanguage: false,
        enableLottie: false,
        useHigherFps: false,
        gridSize: 8,
        exportForPlatform: ExportForPlatform.General,
        studioProfileId: undefined,
        defaultUnit: UnitOptions.Pixels,
        highlightedCharacter: '*'
    },
    templateData: {
        title: Translation.get('topbar.info.title', 'template-designer'),
        description: '',
        brand: '',
        identifier: '',
        image: '',
        type: 'displayAdDesigned',
        createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        updatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        version: 0,
        status: 'draft',
        settings: { dynamicFrames: true, formats: [] },
        public: true,
        customData: {}
    },
    templateSetup: {
        templateVersion: '13',
        finishedMigrations: MIGRATION_SCRIPTS_ORDER
    },
    dataVariables: { base: {}, main: {} }, // This is called Feed Mapping in the code and in the ui. Because that better describes the functionality. But still is called dataVariables because the back end needs that.
    formats: [],
    frameTypes: [
        { title: Translation.get('general.labels.base', 'template-designer'), key: 'base' },
        { title: `${Translation.get('general.labels.frame', 'template-designer')} 1`, key: 'main', duration: 5 }
    ],
    layers: {
        base: [],
        main: []
    },
    layerProperties: {
        general: {
            base: {
                properties: {
                    ...defaultFormatProperties,
                    background: defaultTransparent
                }
            },
            main: {
                properties: defaultFormatProperties
            }
        }
    },
    dynamicLayers: {
        base: [],
        main: []
    },
    url: process.env.TEMPLATES_URL || '',
    view: {
        frameType: 'main',
        currentView: CurrentView.Design,
        showRulers: true,
        showLayoutGrid: true,
        showTimeline: false,
        testEnvironment: {
            open: false,
            uuid: '',
            dataModel: ''
        },
        hideLeftSidebar: false,
        showLayerPanel: false,
        showFormatManagementDialog: false,
        showComments: false,
        showTab: RightSidebarTab.LayerEdit,
        showVersionHistory: false,
        showHelpPanel: false
    },
    state: {
        isPlaying: false,
        openItemTree: false,
        isUploading: false,
        displayFormats: [],
        selectedLayers: [],
        selectedFormats: ['general'],
        selectedFormatSets: [],
        formatOrder: DEFAULT_FORMAT_ORDER,
        restoreVersion: null,
        leftSidebarWidth: 0,
        timelineHeight: 0,
        formatsWithAudio: [],
        changedAfterSave: false,
        activeAnimations: []
    }
};

export default emptyTemplate;
